import * as THREE from 'three';

const createStreetLamp = () => {
  const group = new THREE.Group();

  // Pole
  const poleGeometry = new THREE.CylinderGeometry(0.1, 0.1, 4, 8);
  const poleMaterial = new THREE.MeshStandardMaterial({
    color: 0x333333,
    roughness: 0.7,
    metalness: 0.3
  });
  const pole = new THREE.Mesh(poleGeometry, poleMaterial);
  pole.position.y = 2;
  pole.castShadow = true;
  group.add(pole);

  // Lamp head
  const headGeometry = new THREE.CylinderGeometry(0.3, 0.3, 0.5, 8);
  const headMaterial = new THREE.MeshStandardMaterial({
    color: 0x222222,
    roughness: 0.5,
    metalness: 0.5
  });
  const head = new THREE.Mesh(headGeometry, headMaterial);
  head.position.y = 4;
  head.castShadow = true;
  group.add(head);

  // Light bulb
  const bulbGeometry = new THREE.SphereGeometry(0.2, 16, 16);
  const bulbMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffcc,
    emissive: 0xffffcc,
    emissiveIntensity: 1
  });
  const bulb = new THREE.Mesh(bulbGeometry, bulbMaterial);
  bulb.position.y = 3.8;
  group.add(bulb);

  // Light
  const light = new THREE.PointLight(0xffffcc, 2, 15);
  light.position.copy(bulb.position);
  group.add(light);

  // Add subtle glow
  const glowGeometry = new THREE.SphereGeometry(0.3, 16, 16);
  const glowMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffcc,
    transparent: true,
    opacity: 0.2
  });
  const glow = new THREE.Mesh(glowGeometry, glowMaterial);
  glow.position.copy(bulb.position);
  group.add(glow);

  return group;
};

const createTimeTexture = () => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  canvas.width = 1024;
  canvas.height = 128;

  const texture = new THREE.CanvasTexture(canvas);
  
  const updateTime = () => {
    const now = new Date();
    const estTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    const timeString = estTime.toLocaleTimeString('en-US', { 
      hour: '2-digit', 
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'America/New_York'
    });

    context.fillStyle = '#000000';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = 'bold 72px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    
    // Match the company sign glow effect
    context.shadowColor = '#007bff';
    context.shadowBlur = 30;
    context.fillStyle = '#ffffff';
    context.fillText(timeString + ' EST', canvas.width / 2, canvas.height / 2);

    texture.needsUpdate = true;
  };

  return { texture, updateTime };
};

export const createBuilding = () => {
  const group = new THREE.Group();

  // Red Carpet
  const carpetGeometry = new THREE.PlaneGeometry(6, 45); // Extended length
  const carpetMaterial = new THREE.MeshStandardMaterial({
    color: 0x990000,
    roughness: 0.8,
    metalness: 0.2,
    emissive: 0x330000,
    emissiveIntensity: 0.2
  });
  const carpet = new THREE.Mesh(carpetGeometry, carpetMaterial);
  carpet.rotation.x = -Math.PI / 2;
  carpet.position.set(0, 0.01, 40); // Moved back to align with last lamps
  carpet.receiveShadow = true;
  group.add(carpet);

  // Street Lamps along carpet
  const lampPositions = [
    [-3.5, 0, 20],  // Near building
    [3.5, 0, 20],
    [-3.5, 0, 35],  // Middle
    [3.5, 0, 35],
    [-3.5, 0, 50],  // End of carpet
    [3.5, 0, 50]
  ];

  lampPositions.forEach(([x, y, z]) => {
    const lamp = createStreetLamp();
    lamp.position.set(x, y, z);
    group.add(lamp);
  });

  // Base/Foundation
  const baseGeometry = new THREE.BoxGeometry(40, 3, 40);
  const baseMaterial = new THREE.MeshStandardMaterial({
    color: 0x333333,
    roughness: 0.7,
    metalness: 0.3
  });
  const base = new THREE.Mesh(baseGeometry, baseMaterial);
  base.position.y = 1.5;
  base.receiveShadow = true;
  group.add(base);

  // Main building structure
  const buildingGeometry = new THREE.BoxGeometry(35, 50, 35);
  const buildingMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.2,
    metalness: 0.1
  });
  const building = new THREE.Mesh(buildingGeometry, buildingMaterial);
  building.position.y = 26.5;
  building.castShadow = true;
  building.receiveShadow = true;
  group.add(building);

  // Entrance steps
  const stepsGroup = new THREE.Group();
  for (let i = 0; i < 3; i++) {
    const stepGeometry = new THREE.BoxGeometry(15 - i, 0.5, 3);
    const step = new THREE.Mesh(stepGeometry, baseMaterial);
    step.position.set(0, i * 0.5, 17 + i);
    step.receiveShadow = true;
    stepsGroup.add(step);
  }
  group.add(stepsGroup);

  // Glass entrance
  const entranceGroup = new THREE.Group();
  
  // Main glass doors
  const doorGeometry = new THREE.BoxGeometry(12, 18, 0.2);
  const glassMaterial = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0.1,
    roughness: 0,
    transmission: 0.9,
    thickness: 0.5,
    envMapIntensity: 1
  });
  const doors = new THREE.Mesh(doorGeometry, glassMaterial);
  doors.position.set(0, 9, 17.6);
  entranceGroup.add(doors);

  // Door frame
  const frameGeometry = new THREE.BoxGeometry(13, 19, 0.5);
  const frameMaterial = new THREE.MeshStandardMaterial({
    color: 0x333333,
    roughness: 0.5,
    metalness: 0.8
  });
  const frame = new THREE.Mesh(frameGeometry, frameMaterial);
  frame.position.set(0, 9, 17.5);
  entranceGroup.add(frame);

  group.add(entranceGroup);

  // Windows
  const createWindowSection = (y: number) => {
    const sectionGroup = new THREE.Group();

    // Window panel
    const windowGeometry = new THREE.BoxGeometry(30, 6, 0.2);
    const windowPane = new THREE.Mesh(windowGeometry, glassMaterial);
    windowPane.position.y = y;
    windowPane.position.z = 17.6;
    sectionGroup.add(windowPane);

    // Window frame
    const windowFrameGeometry = new THREE.BoxGeometry(31, 7, 0.4);
    const windowFrame = new THREE.Mesh(windowFrameGeometry, frameMaterial);
    windowFrame.position.y = y;
    windowFrame.position.z = 17.5;
    sectionGroup.add(windowFrame);

    // Vertical dividers
    for (let i = -2; i <= 2; i++) {
      const dividerGeometry = new THREE.BoxGeometry(0.3, 6, 0.3);
      const divider = new THREE.Mesh(dividerGeometry, frameMaterial);
      divider.position.set(i * 6, y, 17.6);
      sectionGroup.add(divider);

      // Add window light
      if (Math.random() > 0.3) { // 70% chance of light being on
        const light = new THREE.PointLight(0xffffcc, Math.random() * 0.3 + 0.2, 8);
        light.position.set(i * 6, y, 16);
        sectionGroup.add(light);
      }
    }

    return sectionGroup;
  };

  // Add window sections
  for (let i = 0; i < 6; i++) {
    const windowSection = createWindowSection(20 + i * 8);
    group.add(windowSection);
  }

  // Decorative horizontal bands
  const bandGeometry = new THREE.BoxGeometry(36, 0.5, 36);
  const bandMaterial = new THREE.MeshStandardMaterial({
    color: 0x444444,
    roughness: 0.5,
    metalness: 0.8
  });

  for (let i = 0; i < 7; i++) {
    const band = new THREE.Mesh(bandGeometry, bandMaterial);
    band.position.y = 16 + i * 8;
    group.add(band);
  }

  // Roof structure
  const roofBaseGeometry = new THREE.BoxGeometry(37, 2, 37);
  const roofBase = new THREE.Mesh(roofBaseGeometry, bandMaterial);
  roofBase.position.y = 52;
  group.add(roofBase);

  // Company sign
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  canvas.width = 2048;
  canvas.height = 256;
  
  context.fillStyle = '#000000';
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.font = 'bold 160px Arial';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.shadowColor = '#007bff';
  context.shadowBlur = 30;
  context.fillStyle = '#ffffff';
  context.fillText('SPARK ARC INC', canvas.width / 2, canvas.height / 2);

  const textTexture = new THREE.CanvasTexture(canvas);
  const signMaterial = new THREE.MeshBasicMaterial({
    map: textTexture,
    transparent: true
  });
  const signGeometry = new THREE.PlaneGeometry(20, 2.5);
  const sign = new THREE.Mesh(signGeometry, signMaterial);
  sign.position.set(0, 45, 17.7);
  group.add(sign);

  // Time display
  const { texture: timeTexture, updateTime } = createTimeTexture();
  const timeMaterial = new THREE.MeshBasicMaterial({
    map: timeTexture,
    transparent: true
  });
  const timeGeometry = new THREE.PlaneGeometry(15, 2);
  const timeDisplay = new THREE.Mesh(timeGeometry, timeMaterial);
  timeDisplay.position.set(0, 42, 17.7);
  group.add(timeDisplay);

  // Add animation function to the group
  group.userData.animate = () => {
    updateTime();
  };

  group.position.set(-50, 0, -50);
  return group;
};
