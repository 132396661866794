import * as THREE from 'three';

export class Snow {
  private particles: THREE.Points;
  private particleGeometry: THREE.BufferGeometry;
  private particleMaterial!: THREE.PointsMaterial;
  private positions: Float32Array;
  private velocities: Float32Array;
  private numParticles: number;

  constructor(scene: THREE.Scene) {
    this.numParticles = 10000;
    this.particleGeometry = new THREE.BufferGeometry();
    this.positions = new Float32Array(this.numParticles * 3);
    this.velocities = new Float32Array(this.numParticles);

    this.initParticles();
    this.createMaterial();
    this.particles = new THREE.Points(this.particleGeometry, this.particleMaterial);
    scene.add(this.particles);
  }

  private initParticles() {
    for (let i = 0; i < this.numParticles * 3; i += 3) {
      // Random x position between -250 and 250
      this.positions[i] = Math.random() * 500 - 250;
      // Random y position between 0 and 100
      this.positions[i + 1] = Math.random() * 100;
      // Random z position between -250 and 250
      this.positions[i + 2] = Math.random() * 500 - 250;
      // Random velocity for each particle
      this.velocities[i / 3] = Math.random() * 0.1 + 0.1;
    }

    this.particleGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(this.positions, 3)
    );
  }

  private createMaterial() {
    // Create a canvas to generate a snowflake texture
    const canvas = document.createElement('canvas');
    canvas.width = 16;
    canvas.height = 16;
    const ctx = canvas.getContext('2d')!;
    
    // Draw a simple snowflake
    ctx.fillStyle = '#ffffff';
    ctx.beginPath();
    ctx.arc(8, 8, 3, 0, Math.PI * 2);
    ctx.fill();

    const snowflakeTexture = new THREE.CanvasTexture(canvas);

    this.particleMaterial = new THREE.PointsMaterial({
      size: 0.5,
      map: snowflakeTexture,
      transparent: true,
      opacity: 0.8,
      color: 0xffffff,
      blending: THREE.AdditiveBlending,
      depthWrite: false
    });
  }

  update() {
    const positions = this.particleGeometry.attributes.position.array as Float32Array;

    for (let i = 0; i < this.numParticles * 3; i += 3) {
      // Update y position
      positions[i + 1] -= this.velocities[i / 3];

      // Add some horizontal movement
      positions[i] += Math.sin(Date.now() * 0.001 + i) * 0.02;

      // Reset particle if it goes below ground
      if (positions[i + 1] < 0) {
        positions[i + 1] = 100;
        positions[i] = Math.random() * 500 - 250;
        positions[i + 2] = Math.random() * 500 - 250;
      }
    }

    this.particleGeometry.attributes.position.needsUpdate = true;
  }

  dispose() {
    this.particleGeometry.dispose();
    this.particleMaterial.dispose();
    if (this.particleMaterial.map) {
      this.particleMaterial.map.dispose();
    }
  }
}
