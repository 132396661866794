import { FC } from 'react';
import './RobotModel.css';

interface RobotModelProps {
  status?: 'pending' | 'approved';
}

const RobotModel: FC<RobotModelProps> = ({ status }) => {
  return (
    <div className="robot-model" data-status={status}>
      <div className="robot-avatar">
        <div className="robot-head">
          <div className="robot-eyes">
            <div className="robot-eye left"></div>
            <div className="robot-eye right"></div>
          </div>
          <div className="robot-antenna"></div>
        </div>
        <div className="robot-body">
          <div className="robot-arms">
            <div className="robot-arm left"></div>
            <div className="robot-arm right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RobotModel;
