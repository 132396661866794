import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { createScene, createCamera, createRenderer, createLighting } from '../components/Verse/Scene';
import { createGround, createStars, createMoon } from '../components/Verse/Environment';
import { createBuilding } from '../components/Verse/Buildings';
import { Snow } from '../components/Verse/Snow';
import '../styles/Verse.css';

interface DisposableObject {
  geometry?: THREE.BufferGeometry;
  material?: THREE.Material | THREE.Material[];
  dispose?: () => void;
}

const Verse = () => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mountRef.current) return;

    // Scene setup
    const scene = createScene();
    const camera = createCamera();
    const renderer = createRenderer();
    mountRef.current.appendChild(renderer.domElement);

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.minDistance = 50; // Updated minimum distance
    controls.maxDistance = 200;
    controls.maxPolarAngle = Math.PI / 2 - 0.1;

    // Environment
    const ground = createGround();
    ground.scale.set(5, 5, 5);
    scene.add(ground);
    scene.add(createStars());
    scene.add(createMoon());

    // Main building
    const building = createBuilding();
    scene.add(building);

    // Lighting
    createLighting(scene);

    // Snow
    const snow = new Snow(scene);

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Set initial camera position to view building from further back
    camera.position.set(-50, 60, 50); // Adjusted for new minimum distance
    controls.target.set(-50, 20, -50);
    controls.update();

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      snow.update();
      if (building.userData.animate) {
        building.userData.animate();
      }
      renderer.render(scene, camera);
    };
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current?.removeChild(renderer.domElement);
      snow.dispose();
      scene.traverse((object) => {
        const disposable = object as DisposableObject;
        if (disposable.geometry) {
          disposable.geometry.dispose();
        }
        if (disposable.material) {
          if (Array.isArray(disposable.material)) {
            disposable.material.forEach(m => m.dispose());
          } else {
            disposable.material.dispose();
          }
        }
      });
      renderer.dispose();
    };
  }, []);

  return (
    <>
      <div ref={mountRef} className="verse-container" />
      <Link to="/" className="verse-back-button">← Back</Link>
    </>
  );
};

export default Verse;
